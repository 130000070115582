<template>
  <v-layout fill-height justify-center align-center column>
    <div class="text-center title font-weight-bold error--text mx-4">
      <p class="mt-4 mb-2">Уважаемый клиент!</p>
      <p class="mb-0">
        С 8.04.2025 изменится способ входа в личный кабинет. Вход будет
        осуществляться с помощью одноразового пароля, который будет приходить на
        электронную почту. Просим убедиться, что в Вашем личном кабинете в
        профиле (правый верхний угол с Вашим ФИО) указан корректный адрес
        электронной почты. Если адрес указан недействительный, пожалуйста,
        обратитесь в техническую поддержку.
      </p>
    </div>
    <div style="width: 490px; max-width: 100vw">
      <v-img
        class="ma-auto mb-3"
        src="@/assets/images/logo-full-blue.svg"
        max-width="340"
        contain
      />
      <v-card>
        <v-card-title class="mb-2 justify-center">
          {{ title }}
        </v-card-title>
        <v-card-text class="pb-0">
          <slot />
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex-column">
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </div>
    <div class="mt-9 text-center text--disabled">
      {{ copyright }}
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    copyright: {
      type: String,
      default: '2017 – ' + new Date().getFullYear() + ' г. © DistMed.com',
    },
  },
};
</script>
